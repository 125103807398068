<template>
  <div>
    <!-- Home Tittle  -->
    <h4 class="text-center text-dark font-semibold mb-base">
      <feather-icon icon="CalendarIcon" svgClasses="h-8 w-8 mb-1 stroke-current text-success" />&nbsp;
      <strong
        class="font-semibold text-success"
      >{{ $t(resources.PeriodDetails.i18n) || resources.PeriodDetail.name }}:</strong>
      {{periodLabel}}
    </h4>
    <vs-row class="mb-2">
      <!-- Descriptions -->
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="6" vs-xs="12">
        <vx-card>
          <vs-table :data="periodItems">
            <template slot="thead">
              <vs-th>{{ $t(resources.Description.i18n) || resources.Description.name}}</vs-th>
              <vs-th>{{ $t(resources.EarningsAmount.i18n) || resources.EarningsAmount.name}}</vs-th>
            </template>
            <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].description">{{ data[indextr].description }}</vs-td>
                <vs-td class="text-right" :data="data[indextr].amount">{{ data[indextr].amount }}</vs-td>
              </vs-tr>
              <vs-tr>
                <vs-td>
                  <strong>{{ $t(resources.Total.i18n) || resources.Total.name}}</strong>
                </vs-td>
                <vs-td class="text-right">
                  <strong>{{ total }}</strong>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <vs-button
            color="rgb(187, 138, 200)"
            type="filled"
            class="mt-2"
            icon-pack="feather"
            icon="icon-download"
            @click="downloadPDF"
          >{{$t(resources.DownloadPDF.i18n) || resources.DownloadPDF.name}}</vs-button>
        </vx-card>
      </vs-col>

      <!-- Chart -->
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="6"
        vs-xs="12"
        class="mt-4"
      >
        <!-- Information about Volumen-->
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
          <vx-card class="mb-base">
            <div class>
              <vue-apex-charts
                type="bar"
                height="350"
                :options="columnChart.chartOptions"
                :series="columnChart.series"
              ></vue-apex-charts>
            </div>
          </vx-card>
        </vs-col>
      </vs-col>
    </vs-row>

    <!-- Detail for commission type -->
    <vs-row>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
        <vx-card :title="$t(resources.Detail.i18n) || resources.Detail.name">
          <vs-collapse>
            <!-- Retail commission -->
            <vs-collapse-item>
              <div
                slot="header"
              >{{$t(resources.RetailCommission.i18n) || resources.RetailCommission.name}}</div>
              <vs-tabs>
                <vs-tab
                  :label="($t(resources.BusinessCenter.i18n) || resources.BusinessCenter.name)+ ' 001'"
                >
                  <div class="tab-text">
                    <vs-table :data="retail1">
                      <template slot="thead">
                        <vs-th>{{ $t(resources.DistributorID.i18n) || resources.DistributorID.name}}</vs-th>
                        <vs-th>{{ $t(resources.Name.i18n) || resources.Name.name}}</vs-th>
                        <vs-th>{{ $t(resources.PaidRank.i18n) || resources.PaidRank.name}}</vs-th>
                        <vs-th>{{ $t(resources.PinRank.i18n) || resources.PinRank.name}}</vs-th>
                        <vs-th>{{ $t(resources.PercentContributed.i18n) || resources.PercentContributed.name}}</vs-th>
                        <vs-th>{{ $t(resources.AmountContributed.i18n) || resources.AmountContributed.name}}</vs-th>
                      </template>
                      <template slot-scope="{data}">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                          <vs-td :data="data[indextr].distributor">{{ data[indextr].distributor }}</vs-td>
                          <vs-td :data="data[indextr].name">{{ data[indextr].name }}</vs-td>
                          <vs-td :data="data[indextr].paidLevel">{{ data[indextr].paidLevel }}</vs-td>
                          <vs-td :data="data[indextr].rank">{{ data[indextr].rank }}</vs-td>
                          <vs-td
                            :data="data[indextr].percentContribuited"
                          >{{ data[indextr].percentContribuited }}</vs-td>
                          <vs-td
                            :data="data[indextr].amountContribuited"
                          >{{ data[indextr].amountContribuited }}</vs-td>
                        </vs-tr>
                      </template>
                    </vs-table>
                  </div>
                </vs-tab>
                <vs-tab
                  :label="($t(resources.BusinessCenter.i18n) || resources.BusinessCenter.name)+ ' 002'"
                >
                  <div class="tab-text">
                    <vs-table :data="retail2">
                      <template slot="thead">
                        <vs-th>{{ $t(resources.DistributorID.i18n) || resources.DistributorID.name}}</vs-th>
                        <vs-th>{{ $t(resources.Name.i18n) || resources.Name.name}}</vs-th>
                        <vs-th>{{ $t(resources.PaidRank.i18n) || resources.PaidRank.name}}</vs-th>
                        <vs-th>{{ $t(resources.PinRank.i18n) || resources.PinRank.name}}</vs-th>
                        <vs-th>{{ $t(resources.PercentContributed.i18n) || resources.PercentContributed.name}}</vs-th>
                        <vs-th>{{ $t(resources.AmountContributed.i18n) || resources.AmountContributed.name}}</vs-th>
                      </template>
                      <template slot-scope="{data}">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                          <vs-td :data="data[indextr].distributor">{{ data[indextr].distributor }}</vs-td>
                          <vs-td :data="data[indextr].name">{{ data[indextr].name }}</vs-td>
                          <vs-td :data="data[indextr].paidLevel">{{ data[indextr].paidLevel }}</vs-td>
                          <vs-td :data="data[indextr].rank">{{ data[indextr].rank }}</vs-td>
                          <vs-td
                            :data="data[indextr].percentContribuited"
                          >{{ data[indextr].percentContribuited }}</vs-td>
                          <vs-td
                            :data="data[indextr].amountContribuited"
                          >{{ data[indextr].amountContribuited }}</vs-td>
                        </vs-tr>
                      </template>
                    </vs-table>
                  </div>
                </vs-tab>
                <vs-tab
                  :label="($t(resources.BusinessCenter.i18n) || resources.BusinessCenter.name)+ ' 003'"
                >
                  <div class="tab-text">
                    <vs-table :data="retail3">
                      <template slot="thead">
                        <vs-th>{{ $t(resources.DistributorID.i18n) || resources.DistributorID.name}}</vs-th>
                        <vs-th>{{ $t(resources.Name.i18n) || resources.Name.name}}</vs-th>
                        <vs-th>{{ $t(resources.PaidRank.i18n) || resources.PaidRank.name}}</vs-th>
                        <vs-th>{{ $t(resources.PinRank.i18n) || resources.PinRank.name}}</vs-th>
                        <vs-th>{{ $t(resources.PercentContributed.i18n) || resources.PercentContributed.name}}</vs-th>
                        <vs-th>{{ $t(resources.AmountContributed.i18n) || resources.AmountContributed.name}}</vs-th>
                      </template>
                      <template slot-scope="{data}">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                          <vs-td :data="data[indextr].distributor">{{ data[indextr].distributor }}</vs-td>
                          <vs-td :data="data[indextr].name">{{ data[indextr].name }}</vs-td>
                          <vs-td :data="data[indextr].paidLevel">{{ data[indextr].paidLevel }}</vs-td>
                          <vs-td :data="data[indextr].rank">{{ data[indextr].rank }}</vs-td>
                          <vs-td
                            :data="data[indextr].percentContribuited"
                          >{{ data[indextr].percentContribuited }}</vs-td>
                          <vs-td
                            :data="data[indextr].amountContribuited"
                          >{{ data[indextr].amountContribuited }}</vs-td>
                        </vs-tr>
                      </template>
                    </vs-table>
                  </div>
                </vs-tab>
              </vs-tabs>
            </vs-collapse-item>

            <!-- Fast start  -->
            <vs-collapse-item>
              <div slot="header">{{$t(resources.FastStart.i18n) || resources.FastStart.name}}</div>
              <vs-tabs>
                <vs-tab
                  :label="($t(resources.BusinessCenter.i18n) || resources.BusinessCenter.name)+ ' 001'"
                >
                  <div class="tab-text">
                    <vs-table :data="fast1">
                      <template slot="thead">
                        <vs-th>{{ $t(resources.DistributorID.i18n) || resources.DistributorID.name}}</vs-th>
                        <vs-th>{{ $t(resources.Name.i18n) || resources.Name.name}}</vs-th>
                        <vs-th>{{ $t(resources.PaidRank.i18n) || resources.PaidRank.name}}</vs-th>
                        <vs-th>{{ $t(resources.PinRank.i18n) || resources.PinRank.name}}</vs-th>
                        <vs-th>{{ $t(resources.PercentContributed.i18n) || resources.PercentContributed.name}}</vs-th>
                        <vs-th>{{ $t(resources.AmountContributed.i18n) || resources.AmountContributed.name}}</vs-th>
                      </template>
                      <template slot-scope="{data}">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                          <vs-td :data="data[indextr].distributor">{{ data[indextr].distributor }}</vs-td>
                          <vs-td :data="data[indextr].name">{{ data[indextr].name }}</vs-td>
                          <vs-td :data="data[indextr].paidLevel">{{ data[indextr].paidLevel }}</vs-td>
                          <vs-td :data="data[indextr].rank">{{ data[indextr].rank }}</vs-td>
                          <vs-td
                            :data="data[indextr].percentContribuited"
                          >{{ data[indextr].percentContribuited }}</vs-td>
                          <vs-td
                            :data="data[indextr].amountContribuited"
                          >{{ data[indextr].amountContribuited }}</vs-td>
                        </vs-tr>
                      </template>
                    </vs-table>
                  </div>
                </vs-tab>
                <vs-tab
                  :label="($t(resources.BusinessCenter.i18n) || resources.BusinessCenter.name)+ ' 002'"
                >
                  <div class="tab-text">
                    <vs-table :data="fast2">
                      <template slot="thead">
                        <vs-th>{{ $t(resources.DistributorID.i18n) || resources.DistributorID.name}}</vs-th>
                        <vs-th>{{ $t(resources.Name.i18n) || resources.Name.name}}</vs-th>
                        <vs-th>{{ $t(resources.PaidRank.i18n) || resources.PaidRank.name}}</vs-th>
                        <vs-th>{{ $t(resources.PinRank.i18n) || resources.PinRank.name}}</vs-th>
                        <vs-th>{{ $t(resources.PercentContributed.i18n) || resources.PercentContributed.name}}</vs-th>
                        <vs-th>{{ $t(resources.AmountContributed.i18n) || resources.AmountContributed.name}}</vs-th>
                      </template>
                      <template slot-scope="{data}">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                          <vs-td :data="data[indextr].distributor">{{ data[indextr].distributor }}</vs-td>
                          <vs-td :data="data[indextr].name">{{ data[indextr].name }}</vs-td>
                          <vs-td :data="data[indextr].paidLevel">{{ data[indextr].paidLevel }}</vs-td>
                          <vs-td :data="data[indextr].rank">{{ data[indextr].rank }}</vs-td>
                          <vs-td
                            :data="data[indextr].percentContribuited"
                          >{{ data[indextr].percentContribuited }}</vs-td>
                          <vs-td
                            :data="data[indextr].amountContribuited"
                          >{{ data[indextr].amountContribuited }}</vs-td>
                        </vs-tr>
                      </template>
                    </vs-table>
                  </div>
                </vs-tab>
                <vs-tab
                  :label="($t(resources.BusinessCenter.i18n) || resources.BusinessCenter.name)+ ' 003'"
                >
                  <div class="tab-text">
                    <vs-table :data="fast3">
                      <template slot="thead">
                        <vs-th>{{ $t(resources.DistributorID.i18n) || resources.DistributorID.name}}</vs-th>
                        <vs-th>{{ $t(resources.Name.i18n) || resources.Name.name}}</vs-th>
                        <vs-th>{{ $t(resources.PaidRank.i18n) || resources.PaidRank.name}}</vs-th>
                        <vs-th>{{ $t(resources.PinRank.i18n) || resources.PinRank.name}}</vs-th>
                        <vs-th>{{ $t(resources.PercentContributed.i18n) || resources.PercentContributed.name}}</vs-th>
                        <vs-th>{{ $t(resources.AmountContributed.i18n) || resources.AmountContributed.name}}</vs-th>
                      </template>
                      <template slot-scope="{data}">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                          <vs-td :data="data[indextr].distributor">{{ data[indextr].distributor }}</vs-td>
                          <vs-td :data="data[indextr].name">{{ data[indextr].name }}</vs-td>
                          <vs-td :data="data[indextr].paidLevel">{{ data[indextr].paidLevel }}</vs-td>
                          <vs-td :data="data[indextr].rank">{{ data[indextr].rank }}</vs-td>
                          <vs-td
                            :data="data[indextr].percentContribuited"
                          >{{ data[indextr].percentContribuited }}</vs-td>
                          <vs-td
                            :data="data[indextr].amountContribuited"
                          >{{ data[indextr].amountContribuited }}</vs-td>
                        </vs-tr>
                      </template>
                    </vs-table>
                  </div>
                </vs-tab>
              </vs-tabs>
            </vs-collapse-item>

            <!-- Cycle commission -->
            <vs-collapse-item>
              <div
                slot="header"
              >{{$t(resources.CycleCommission.i18n) || resources.CycleCommission.name}}</div>
              <vs-tabs>
                <vs-tab
                  :label="($t(resources.BusinessCenter.i18n) || resources.BusinessCenter.name)+ ' 001'"
                >
                  <div class="tab-text">
                    <vs-table :data="cycle1">
                      <template slot="thead">
                        <vs-th>{{ $t(resources.DistributorID.i18n) || resources.DistributorID.name}}</vs-th>
                        <vs-th>{{ $t(resources.Name.i18n) || resources.Name.name}}</vs-th>
                        <vs-th>{{ $t(resources.PaidRank.i18n) || resources.PaidRank.name}}</vs-th>
                        <vs-th>{{ $t(resources.PinRank.i18n) || resources.PinRank.name}}</vs-th>
                        <vs-th>{{ $t(resources.PercentContributed.i18n) || resources.PercentContributed.name}}</vs-th>
                        <vs-th>{{ $t(resources.AmountContributed.i18n) || resources.AmountContributed.name}}</vs-th>
                      </template>
                      <template slot-scope="{data}">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                          <vs-td :data="data[indextr].distributor">{{ data[indextr].distributor }}</vs-td>
                          <vs-td :data="data[indextr].name">{{ data[indextr].name }}</vs-td>
                          <vs-td :data="data[indextr].paidLevel">{{ data[indextr].paidLevel }}</vs-td>
                          <vs-td :data="data[indextr].rank">{{ data[indextr].rank }}</vs-td>
                          <vs-td
                            :data="data[indextr].percentContribuited"
                          >{{ data[indextr].percentContribuited }}</vs-td>
                          <vs-td
                            :data="data[indextr].amountContribuited"
                          >{{ data[indextr].amountContribuited }}</vs-td>
                        </vs-tr>
                      </template>
                    </vs-table>
                  </div>
                </vs-tab>
                <vs-tab
                  :label="($t(resources.BusinessCenter.i18n) || resources.BusinessCenter.name)+ ' 002'"
                >
                  <div class="tab-text">
                    <vs-table :data="cycle2">
                      <template slot="thead">
                        <vs-th>{{ $t(resources.DistributorID.i18n) || resources.DistributorID.name}}</vs-th>
                        <vs-th>{{ $t(resources.Name.i18n) || resources.Name.name}}</vs-th>
                        <vs-th>{{ $t(resources.PaidRank.i18n) || resources.PaidRank.name}}</vs-th>
                        <vs-th>{{ $t(resources.PinRank.i18n) || resources.PinRank.name}}</vs-th>
                        <vs-th>{{ $t(resources.PercentContributed.i18n) || resources.PercentContributed.name}}</vs-th>
                        <vs-th>{{ $t(resources.AmountContributed.i18n) || resources.AmountContributed.name}}</vs-th>
                      </template>
                      <template slot-scope="{data}">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                          <vs-td :data="data[indextr].distributor">{{ data[indextr].distributor }}</vs-td>
                          <vs-td :data="data[indextr].name">{{ data[indextr].name }}</vs-td>
                          <vs-td :data="data[indextr].paidLevel">{{ data[indextr].paidLevel }}</vs-td>
                          <vs-td :data="data[indextr].rank">{{ data[indextr].rank }}</vs-td>
                          <vs-td
                            :data="data[indextr].percentContribuited"
                          >{{ data[indextr].percentContribuited }}</vs-td>
                          <vs-td
                            :data="data[indextr].amountContribuited"
                          >{{ data[indextr].amountContribuited }}</vs-td>
                        </vs-tr>
                      </template>
                    </vs-table>
                  </div>
                </vs-tab>
                <vs-tab
                  :label="($t(resources.BusinessCenter.i18n) || resources.BusinessCenter.name)+ ' 003'"
                >
                  <div class="tab-text">
                    <vs-table :data="cycle3">
                      <template slot="thead">
                        <vs-th>{{ $t(resources.DistributorID.i18n) || resources.DistributorID.name}}</vs-th>
                        <vs-th>{{ $t(resources.Name.i18n) || resources.Name.name}}</vs-th>
                        <vs-th>{{ $t(resources.PaidRank.i18n) || resources.PaidRank.name}}</vs-th>
                        <vs-th>{{ $t(resources.PinRank.i18n) || resources.PinRank.name}}</vs-th>
                        <vs-th>{{ $t(resources.PercentContributed.i18n) || resources.PercentContributed.name}}</vs-th>
                        <vs-th>{{ $t(resources.AmountContributed.i18n) || resources.AmountContributed.name}}</vs-th>
                      </template>
                      <template slot-scope="{data}">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                          <vs-td :data="data[indextr].distributor">{{ data[indextr].distributor }}</vs-td>
                          <vs-td :data="data[indextr].name">{{ data[indextr].name }}</vs-td>
                          <vs-td :data="data[indextr].paidLevel">{{ data[indextr].paidLevel }}</vs-td>
                          <vs-td :data="data[indextr].rank">{{ data[indextr].rank }}</vs-td>
                          <vs-td
                            :data="data[indextr].percentContribuited"
                          >{{ data[indextr].percentContribuited }}</vs-td>
                          <vs-td
                            :data="data[indextr].amountContribuited"
                          >{{ data[indextr].amountContribuited }}</vs-td>
                        </vs-tr>
                      </template>
                    </vs-table>
                  </div>
                </vs-tab>
              </vs-tabs>
            </vs-collapse-item>

            <!-- Matching bonus -->
            <vs-collapse-item>
              <div
                slot="header"
              >{{$t(resources.MatchingBonus.i18n) || resources.MatchingBonus.name}}</div>
              <vs-tabs>
                <vs-tab
                  :label="($t(resources.BusinessCenter.i18n) || resources.BusinessCenter.name)+ ' 001'"
                >
                  <div class="tab-text">
                    <vs-table :data="matching1">
                      <template slot="thead">
                        <vs-th>{{ $t(resources.DistributorID.i18n) || resources.DistributorID.name}}</vs-th>
                        <vs-th>{{ $t(resources.Name.i18n) || resources.Name.name}}</vs-th>
                        <vs-th>{{ $t(resources.PaidRank.i18n) || resources.PaidRank.name}}</vs-th>
                        <vs-th>{{ $t(resources.PinRank.i18n) || resources.PinRank.name}}</vs-th>
                        <vs-th>{{ $t(resources.PercentContributed.i18n) || resources.PercentContributed.name}}</vs-th>
                        <vs-th>{{ $t(resources.AmountContributed.i18n) || resources.AmountContributed.name}}</vs-th>
                      </template>
                      <template slot-scope="{data}">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                          <vs-td :data="data[indextr].distributor">{{ data[indextr].distributor }}</vs-td>
                          <vs-td :data="data[indextr].name">{{ data[indextr].name }}</vs-td>
                          <vs-td :data="data[indextr].paidLevel">{{ data[indextr].paidLevel }}</vs-td>
                          <vs-td :data="data[indextr].rank">{{ data[indextr].rank }}</vs-td>
                          <vs-td
                            :data="data[indextr].percentContribuited"
                          >{{ data[indextr].percentContribuited }}</vs-td>
                          <vs-td
                            :data="data[indextr].amountContribuited"
                          >{{ data[indextr].amountContribuited }}</vs-td>
                        </vs-tr>
                      </template>
                    </vs-table>
                  </div>
                </vs-tab>
                <vs-tab
                  :label="($t(resources.BusinessCenter.i18n) || resources.BusinessCenter.name)+ ' 002'"
                >
                  <div class="tab-text">
                    <vs-table :data="matching2">
                      <template slot="thead">
                        <vs-th>{{ $t(resources.DistributorID.i18n) || resources.DistributorID.name}}</vs-th>
                        <vs-th>{{ $t(resources.Name.i18n) || resources.Name.name}}</vs-th>
                        <vs-th>{{ $t(resources.PaidRank.i18n) || resources.PaidRank.name}}</vs-th>
                        <vs-th>{{ $t(resources.PinRank.i18n) || resources.PinRank.name}}</vs-th>
                        <vs-th>{{ $t(resources.PercentContributed.i18n) || resources.PercentContributed.name}}</vs-th>
                        <vs-th>{{ $t(resources.AmountContributed.i18n) || resources.AmountContributed.name}}</vs-th>
                      </template>
                      <template slot-scope="{data}">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                          <vs-td :data="data[indextr].distributor">{{ data[indextr].distributor }}</vs-td>
                          <vs-td :data="data[indextr].name">{{ data[indextr].name }}</vs-td>
                          <vs-td :data="data[indextr].paidLevel">{{ data[indextr].paidLevel }}</vs-td>
                          <vs-td :data="data[indextr].rank">{{ data[indextr].rank }}</vs-td>
                          <vs-td
                            :data="data[indextr].percentContribuited"
                          >{{ data[indextr].percentContribuited }}</vs-td>
                          <vs-td
                            :data="data[indextr].amountContribuited"
                          >{{ data[indextr].amountContribuited }}</vs-td>
                        </vs-tr>
                      </template>
                    </vs-table>
                  </div>
                </vs-tab>
                <vs-tab
                  :label="($t(resources.BusinessCenter.i18n) || resources.BusinessCenter.name)+ ' 003'"
                >
                  <div class="tab-text">
                    <vs-table :data="matching3">
                      <template slot="thead">
                        <vs-th>{{ $t(resources.DistributorID.i18n) || resources.DistributorID.name}}</vs-th>
                        <vs-th>{{ $t(resources.Name.i18n) || resources.Name.name}}</vs-th>
                        <vs-th>{{ $t(resources.PaidRank.i18n) || resources.PaidRank.name}}</vs-th>
                        <vs-th>{{ $t(resources.PinRank.i18n) || resources.PinRank.name}}</vs-th>
                        <vs-th>{{ $t(resources.PercentContributed.i18n) || resources.PercentContributed.name}}</vs-th>
                        <vs-th>{{ $t(resources.AmountContributed.i18n) || resources.AmountContributed.name}}</vs-th>
                      </template>
                      <template slot-scope="{data}">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                          <vs-td :data="data[indextr].distributor">{{ data[indextr].distributor }}</vs-td>
                          <vs-td :data="data[indextr].name">{{ data[indextr].name }}</vs-td>
                          <vs-td :data="data[indextr].paidLevel">{{ data[indextr].paidLevel }}</vs-td>
                          <vs-td :data="data[indextr].rank">{{ data[indextr].rank }}</vs-td>
                          <vs-td
                            :data="data[indextr].percentContribuited"
                          >{{ data[indextr].percentContribuited }}</vs-td>
                          <vs-td
                            :data="data[indextr].amountContribuited"
                          >{{ data[indextr].amountContribuited }}</vs-td>
                        </vs-tr>
                      </template>
                    </vs-table>
                  </div>
                </vs-tab>
              </vs-tabs>
            </vs-collapse-item>

            <!-- Base bonus -->
            <vs-collapse-item>
              <div slot="header">{{$t(resources.BaseBonus.i18n) || resources.BaseBonus.name}}</div>
              <vs-tabs>
                <vs-tab
                  :label="($t(resources.BusinessCenter.i18n) || resources.BusinessCenter.name)+ ' 001'"
                >
                  <div class="tab-text">
                    <vs-table :data="bonus1">
                      <template slot="thead">
                        <vs-th>{{ $t(resources.DistributorID.i18n) || resources.DistributorID.name}}</vs-th>
                        <vs-th>{{ $t(resources.Name.i18n) || resources.Name.name}}</vs-th>
                        <vs-th>{{ $t(resources.PaidRank.i18n) || resources.PaidRank.name}}</vs-th>
                        <vs-th>{{ $t(resources.PinRank.i18n) || resources.PinRank.name}}</vs-th>
                        <vs-th>{{ $t(resources.PercentContributed.i18n) || resources.PercentContributed.name}}</vs-th>
                        <vs-th>{{ $t(resources.AmountContributed.i18n) || resources.AmountContributed.name}}</vs-th>
                      </template>
                      <template slot-scope="{data}">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                          <vs-td :data="data[indextr].distributor">{{ data[indextr].distributor }}</vs-td>
                          <vs-td :data="data[indextr].name">{{ data[indextr].name }}</vs-td>
                          <vs-td :data="data[indextr].paidLevel">{{ data[indextr].paidLevel }}</vs-td>
                          <vs-td :data="data[indextr].rank">{{ data[indextr].rank }}</vs-td>
                          <vs-td
                            :data="data[indextr].percentContribuited"
                          >{{ data[indextr].percentContribuited }}</vs-td>
                          <vs-td
                            :data="data[indextr].amountContribuited"
                          >{{ data[indextr].amountContribuited }}</vs-td>
                        </vs-tr>
                      </template>
                    </vs-table>
                  </div>
                </vs-tab>
                <vs-tab
                  :label="($t(resources.BusinessCenter.i18n) || resources.BusinessCenter.name)+ ' 002'"
                >
                  <div class="tab-text">
                    <vs-table :data="bonus2">
                      <template slot="thead">
                        <vs-th>{{ $t(resources.DistributorID.i18n) || resources.DistributorID.name}}</vs-th>
                        <vs-th>{{ $t(resources.Name.i18n) || resources.Name.name}}</vs-th>
                        <vs-th>{{ $t(resources.PaidRank.i18n) || resources.PaidRank.name}}</vs-th>
                        <vs-th>{{ $t(resources.PinRank.i18n) || resources.PinRank.name}}</vs-th>
                        <vs-th>{{ $t(resources.PercentContributed.i18n) || resources.PercentContributed.name}}</vs-th>
                        <vs-th>{{ $t(resources.AmountContributed.i18n) || resources.AmountContributed.name}}</vs-th>
                      </template>
                      <template slot-scope="{data}">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                          <vs-td :data="data[indextr].distributor">{{ data[indextr].distributor }}</vs-td>
                          <vs-td :data="data[indextr].name">{{ data[indextr].name }}</vs-td>
                          <vs-td :data="data[indextr].paidLevel">{{ data[indextr].paidLevel }}</vs-td>
                          <vs-td :data="data[indextr].rank">{{ data[indextr].rank }}</vs-td>
                          <vs-td
                            :data="data[indextr].percentContribuited"
                          >{{ data[indextr].percentContribuited }}</vs-td>
                          <vs-td
                            :data="data[indextr].amountContribuited"
                          >{{ data[indextr].amountContribuited }}</vs-td>
                        </vs-tr>
                      </template>
                    </vs-table>
                  </div>
                </vs-tab>
                <vs-tab
                  :label="($t(resources.BusinessCenter.i18n) || resources.BusinessCenter.name)+ ' 003'"
                >
                  <div class="tab-text">
                    <vs-table :data="bonus3">
                      <template slot="thead">
                        <vs-th>{{ $t(resources.DistributorID.i18n) || resources.DistributorID.name}}</vs-th>
                        <vs-th>{{ $t(resources.Name.i18n) || resources.Name.name}}</vs-th>
                        <vs-th>{{ $t(resources.PaidRank.i18n) || resources.PaidRank.name}}</vs-th>
                        <vs-th>{{ $t(resources.PinRank.i18n) || resources.PinRank.name}}</vs-th>
                        <vs-th>{{ $t(resources.PercentContributed.i18n) || resources.PercentContributed.name}}</vs-th>
                        <vs-th>{{ $t(resources.AmountContributed.i18n) || resources.AmountContributed.name}}</vs-th>
                      </template>
                      <template slot-scope="{data}">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                          <vs-td :data="data[indextr].distributor">{{ data[indextr].distributor }}</vs-td>
                          <vs-td :data="data[indextr].name">{{ data[indextr].name }}</vs-td>
                          <vs-td :data="data[indextr].paidLevel">{{ data[indextr].paidLevel }}</vs-td>
                          <vs-td :data="data[indextr].rank">{{ data[indextr].rank }}</vs-td>
                          <vs-td
                            :data="data[indextr].percentContribuited"
                          >{{ data[indextr].percentContribuited }}</vs-td>
                          <vs-td
                            :data="data[indextr].amountContribuited"
                          >{{ data[indextr].amountContribuited }}</vs-td>
                        </vs-tr>
                      </template>
                    </vs-table>
                  </div>
                </vs-tab>
              </vs-tabs>
            </vs-collapse-item>

            <!-- Lifestyle bonus -->
            <vs-collapse-item>
              <div
                slot="header"
              >{{$t(resources.LifestyleBonus.i18n) || resources.LifestyleBonus.name}}</div>
              <vs-tabs>
                <vs-tab
                  :label="($t(resources.BusinessCenter.i18n) || resources.BusinessCenter.name)+ ' 001'"
                >
                  <div class="tab-text">
                    <vs-table :data="lifestyle1">
                      <template slot="thead">
                        <vs-th>{{ $t(resources.DistributorID.i18n) || resources.DistributorID.name}}</vs-th>
                        <vs-th>{{ $t(resources.Name.i18n) || resources.Name.name}}</vs-th>
                        <vs-th>{{ $t(resources.PaidRank.i18n) || resources.PaidRank.name}}</vs-th>
                        <vs-th>{{ $t(resources.PinRank.i18n) || resources.PinRank.name}}</vs-th>
                        <vs-th>{{ $t(resources.PercentContributed.i18n) || resources.PercentContributed.name}}</vs-th>
                        <vs-th>{{ $t(resources.AmountContributed.i18n) || resources.AmountContributed.name}}</vs-th>
                      </template>
                      <template slot-scope="{data}">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                          <vs-td :data="data[indextr].distributor">{{ data[indextr].distributor }}</vs-td>
                          <vs-td :data="data[indextr].name">{{ data[indextr].name }}</vs-td>
                          <vs-td :data="data[indextr].paidLevel">{{ data[indextr].paidLevel }}</vs-td>
                          <vs-td :data="data[indextr].rank">{{ data[indextr].rank }}</vs-td>
                          <vs-td
                            :data="data[indextr].percentContribuited"
                          >{{ data[indextr].percentContribuited }}</vs-td>
                          <vs-td
                            :data="data[indextr].amountContribuited"
                          >{{ data[indextr].amountContribuited }}</vs-td>
                        </vs-tr>
                      </template>
                    </vs-table>
                  </div>
                </vs-tab>
                <vs-tab
                  :label="($t(resources.BusinessCenter.i18n) || resources.BusinessCenter.name)+ ' 002'"
                >
                  <div class="tab-text">
                    <vs-table :data="lifestyle2">
                      <template slot="thead">
                        <vs-th>{{ $t(resources.DistributorID.i18n) || resources.DistributorID.name}}</vs-th>
                        <vs-th>{{ $t(resources.Name.i18n) || resources.Name.name}}</vs-th>
                        <vs-th>{{ $t(resources.PaidRank.i18n) || resources.PaidRank.name}}</vs-th>
                        <vs-th>{{ $t(resources.PinRank.i18n) || resources.PinRank.name}}</vs-th>
                        <vs-th>{{ $t(resources.PercentContributed.i18n) || resources.PercentContributed.name}}</vs-th>
                        <vs-th>{{ $t(resources.AmountContributed.i18n) || resources.AmountContributed.name}}</vs-th>
                      </template>
                      <template slot-scope="{data}">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                          <vs-td :data="data[indextr].distributor">{{ data[indextr].distributor }}</vs-td>
                          <vs-td :data="data[indextr].name">{{ data[indextr].name }}</vs-td>
                          <vs-td :data="data[indextr].paidLevel">{{ data[indextr].paidLevel }}</vs-td>
                          <vs-td :data="data[indextr].rank">{{ data[indextr].rank }}</vs-td>
                          <vs-td
                            :data="data[indextr].percentContribuited"
                          >{{ data[indextr].percentContribuited }}</vs-td>
                          <vs-td
                            :data="data[indextr].amountContribuited"
                          >{{ data[indextr].amountContribuited }}</vs-td>
                        </vs-tr>
                      </template>
                    </vs-table>
                  </div>
                </vs-tab>
                <vs-tab
                  :label="($t(resources.BusinessCenter.i18n) || resources.BusinessCenter.name)+ ' 003'"
                >
                  <div class="tab-text">
                    <vs-table :data="lifestyle3">
                      <template slot="thead">
                        <vs-th>{{ $t(resources.DistributorID.i18n) || resources.DistributorID.name}}</vs-th>
                        <vs-th>{{ $t(resources.Name.i18n) || resources.Name.name}}</vs-th>
                        <vs-th>{{ $t(resources.PaidRank.i18n) || resources.PaidRank.name}}</vs-th>
                        <vs-th>{{ $t(resources.PinRank.i18n) || resources.PinRank.name}}</vs-th>
                        <vs-th>{{ $t(resources.PercentContributed.i18n) || resources.PercentContributed.name}}</vs-th>
                        <vs-th>{{ $t(resources.AmountContributed.i18n) || resources.AmountContributed.name}}</vs-th>
                      </template>
                      <template slot-scope="{data}">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                          <vs-td :data="data[indextr].distributor">{{ data[indextr].distributor }}</vs-td>
                          <vs-td :data="data[indextr].name">{{ data[indextr].name }}</vs-td>
                          <vs-td :data="data[indextr].paidLevel">{{ data[indextr].paidLevel }}</vs-td>
                          <vs-td :data="data[indextr].rank">{{ data[indextr].rank }}</vs-td>
                          <vs-td
                            :data="data[indextr].percentContribuited"
                          >{{ data[indextr].percentContribuited }}</vs-td>
                          <vs-td
                            :data="data[indextr].amountContribuited"
                          >{{ data[indextr].amountContribuited }}</vs-td>
                        </vs-tr>
                      </template>
                    </vs-table>
                  </div>
                </vs-tab>
              </vs-tabs>
            </vs-collapse-item>
          </vs-collapse>
        </vx-card>
      </vs-col>
    </vs-row>

    <vs-row vs-type="flex" vs-justify="flex-end">
      <!-- Descriptions -->
      <vs-button
        color="rgb(174,174,174)"
        type="filled"
        @click="$router.push('/commissions/Index')"
        class="mt-2"
      >{{$t(resources.Return.i18n) || resources.Return.name}}</vs-button>
    </vs-row>
  </div>
</template>
<script>
import ranks from "@/assets/resources/ranks.js";
import { AgGridVue } from "ag-grid-vue";
import axios from "axios";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import resources from "@/i18n/resources.js";
import VueApexCharts from "vue-apexcharts";
import jspdf from "jspdf";
import logo_base64 from "@/assets/images/logo/logo_base64.js";
import "jspdf-autotable";

export default {
  components: {
    AgGridVue,
    VueApexCharts
  },
  data() {
    return {
      period: 0,
      transaction: "",
      date: "",

      resources: resources,
      periodItems: "",
      itaCode: JSON.parse(localStorage.getItem("userInfo")).itaCode,
      summary: "",
      columnChart: {
        series: [
          {
            name: this.$i18n.t("Amount"),
            data: [0]
          }
        ],
        chartOptions: {
          colors: [
            "#FF9F43",
            "#EA5455",
            "#28C76F",
            "#7367F0",
            "#1E1E1E",
            "#7367F0"
          ],
          chart: {
            id: "chart1"
          },
          plotOptions: {
            bar: {
              horizontal: true,
              endingShape: "rounded",
              columnWidth: "30%"
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 5,
            colors: ["transparent"]
          },

          xaxis: {
            categories: [
              this.$i18n.t("RetailCommission"),
              this.$i18n.t("FastStart"),
              this.$i18n.t("CycleCommission"),
              this.$i18n.t("MatchingBonus"),
              this.$i18n.t("BaseBonus"),
              this.$i18n.t("LifestyleBonus")
            ]
          },
          yaxis: {
            title: {
              text: "$ (USD)"
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function(val) {
                return "USD " + val;
              }
            }
          }
        }
      },
      retail1: "",
      fast1: "",
      cycle1: "",
      matching1: "",
      bonus1: "",
      lifestyle1: "",
      retail2: "",
      fast2: "",
      cycle2: "",
      matching2: "",
      bonus2: "",
      lifestyle2: "",
      retail3: "",
      fast3: "",
      cycle3: "",
      matching3: "",
      bonus3: "",
      lifestyle3: "",
      imgLogo: logo_base64.image,
      ranks: ranks,
      total: 0
    };
  },
  created() {
    this.period = this.$route.params.period;
    this.transaction = this.$route.params.transaction;
    this.date = this.$route.params.date;
    this.getPaidOnDetail();
  },
  computed: {
    periodLabel() {
      let period = this.period.substring(4) + "/" + this.period.substring(0, 4);
      return period;
    }
  },
  methods: {
    //Download PDF with commissions summary
    downloadPDF() {
      var doc = new jspdf();
      let user = JSON.parse(localStorage.getItem("userInfo"));
      let data = this.summary;
      let address = user.address;
      let city = user.cityName + ", " + user.stateName;

      var img = this.imgLogo;
      //this.getInvoice(orderId);
      let pdfName = "SummaryCommission_" + this.period + ".pdf";

      doc.addImage(img, "png", 7, 5, 80, 20, undefined, "none");
      //doc.setFont("'Montserrat', Helvetica, Arial, sans-serif");
      doc.setFontSize(10);

      doc.text(150, 15, this.$i18n.t("DistributorID") + ": " + this.itaCode);
      doc.text(150, 20, address);
      doc.text(150, 25, city);
      doc.text(150, 30, user.countryName);
      doc.text(
        10,
        35,
        this.$i18n.t("DistributorID") +
          ": " +
          this.itaCode +
          " - " +
          user.name +
          " " +
          user.lastName
      );
      doc.text(
        10,
        40,
        this.$i18n.t("Period") +
          ": " +
          this.period.substring(4) +
          "/" +
          this.period.substring(0, 4)
      );

      let paidrank = ranks.find(ranks => ranks.value == data.PAID_LEVEL[0])
        .i18n;
      doc.text(
        10,
        45,
        this.$i18n.t("PaidRank") + ": " + this.$i18n.t(paidrank)
      );
      doc.text(
        10,
        50,
        this.$i18n.t("PinRank") +
          ": " +
          this.$i18n.t(ranks.find(ranks => ranks.value == data.RANK[0]).i18n)
      );

      doc.setFontType("bold");
      doc.text(80, 60, this.$i18n.t("TotalCommissionsEarned"));

      doc.autoTable({
        startY: 65,
        head: [
          [
            this.$i18n.t("Date"),
            this.$i18n.t("Invoice"),
            this.$i18n.t("Description"),
            this.$i18n.t("Country"),
            this.$i18n.t("Amount")
          ]
        ],
        body: [
          [
            this.date,
            this.transaction,
            this.$i18n.t("PaymentsReceived"),
            data.SOURCE_COUNTRY[0],
            data.CURRENCY_CODE[0] + " " + this.total.toString()
          ]
        ]
      });

      let header = [
        [
          this.$i18n.t("DistributorID"),
          this.$i18n.t("Name"),
          this.$i18n.t("Amount"),
          this.$i18n.t("OrderNumber")
        ]
      ];

      if (this.retail1.length > 0) {
        let finalY = doc.previousAutoTable.finalY;
        finalY = finalY + 7;
        doc.text(
          13,
          finalY,
          this.$i18n.t("RetailCommission") +
            " - " +
            this.$i18n.t("BusinessCenter") +
            " 001"
        );
        let body = this.retail1.map(row =>
          Object.keys(row).map(key => row[key])
        );

        doc.autoTable({
          startY: finalY + 7,
          head: header,
          body: body
        });
      }

      if (this.retail2.length > 0) {
        let finalY = doc.previousAutoTable.finalY;
        finalY = finalY + 7;
        doc.text(
          13,
          finalY,
          this.$i18n.t("RetailCommission") +
            " - " +
            this.$i18n.t("BusinessCenter") +
            " 002"
        );
        let body = this.retail2.map(row =>
          Object.keys(row).map(key => row[key])
        );

        doc.autoTable({
          startY: finalY + 7,
          head: header,
          body: body
        });
      }

      if (this.retail3.length > 0) {
        let finalY = doc.previousAutoTable.finalY;
        finalY = finalY + 7;
        doc.text(
          13,
          finalY,
          this.$i18n.t("RetailCommission") +
            " - " +
            this.$i18n.t("BusinessCenter") +
            " 003"
        );
        let body = this.retail3.map(row =>
          Object.keys(row).map(key => row[key])
        );

        doc.autoTable({
          startY: finalY + 7,
          head: header,
          body: body
        });
      }

      if (this.fast1.length > 0) {
        let finalY = doc.previousAutoTable.finalY;
        finalY = finalY + 7;
        doc.text(
          13,
          finalY,
          this.$i18n.t("FastStart") +
            " - " +
            this.$i18n.t("BusinessCenter") +
            " 001"
        );
        let body = this.fast1.map(row => Object.keys(row).map(key => row[key]));

        doc.autoTable({
          startY: finalY + 7,
          head: header,
          body: body
        });
      }

      if (this.fast2.length > 0) {
        let finalY = doc.previousAutoTable.finalY;
        finalY = finalY + 7;
        doc.text(
          13,
          finalY,
          this.$i18n.t("FastStart") +
            " - " +
            this.$i18n.t("BusinessCenter") +
            " 002"
        );
        let body = this.fast2.map(row => Object.keys(row).map(key => row[key]));

        doc.autoTable({
          startY: finalY + 7,
          head: header,
          body: body
        });
      }

      if (this.fast3.length > 0) {
        let finalY = doc.previousAutoTable.finalY;
        finalY = finalY + 7;
        doc.text(
          13,
          finalY,
          this.$i18n.t("FastStart") +
            " - " +
            this.$i18n.t("BusinessCenter") +
            " 003"
        );
        let body = this.fast3.map(row => Object.keys(row).map(key => row[key]));

        doc.autoTable({
          startY: finalY + 7,
          head: header,
          body: body
        });
      }

      if (this.cycle1.length > 0) {
        let finalY = doc.previousAutoTable.finalY;
        finalY = finalY + 7;
        doc.text(
          13,
          finalY,
          this.$i18n.t("CycleCommission") +
            " - " +
            this.$i18n.t("BusinessCenter") +
            " 001"
        );
        let body = this.cycle1.map(row =>
          Object.keys(row).map(key => row[key])
        );

        doc.autoTable({
          startY: finalY + 7,
          head: [
            [
              this.$i18n.t("DistributorID"),
              this.$i18n.t("Name"),
              this.$i18n.t("Amount")
            ]
          ],
          body: body
        });
      }

      if (this.cycle2.length > 0) {
        let finalY = doc.previousAutoTable.finalY;
        finalY = finalY + 7;
        doc.text(
          13,
          finalY,
          this.$i18n.t("CycleCommission") +
            " - " +
            this.$i18n.t("BusinessCenter") +
            " 002"
        );
        let body = this.cycle2.map(row =>
          Object.keys(row).map(key => row[key])
        );

        doc.autoTable({
          startY: finalY + 7,
          head: [
            [
              this.$i18n.t("DistributorID"),
              this.$i18n.t("Name"),
              this.$i18n.t("Amount")
            ]
          ],
          body: body
        });
      }

      if (this.cycle3.length > 0) {
        let finalY = doc.previousAutoTable.finalY;
        finalY = finalY + 7;
        doc.text(
          13,
          finalY,
          this.$i18n.t("CycleCommission") +
            " - " +
            this.$i18n.t("BusinessCenter") +
            " 003"
        );
        let body = this.cycle3.map(row =>
          Object.keys(row).map(key => row[key])
        );

        doc.autoTable({
          startY: finalY + 7,
          head: [
            [
              this.$i18n.t("DistributorID"),
              this.$i18n.t("Name"),
              this.$i18n.t("Amount")
            ]
          ],
          body: body
        });
      }

      if (this.matching1.length > 0) {
        let finalY = doc.previousAutoTable.finalY;
        finalY = finalY + 7;
        doc.text(
          13,
          finalY,
          this.$i18n.t("MatchingBonus") +
            " - " +
            this.$i18n.t("BusinessCenter") +
            " 001"
        );

        let body = this.matching1.map(row =>
          Object.keys(row).map(key => row[key])
        );

        doc.autoTable({
          startY: finalY + 7,
          head: header,
          body: body
        });
      }

      if (this.matching2.length > 0) {
        let finalY = doc.previousAutoTable.finalY;
        finalY = finalY + 7;
        doc.text(
          13,
          finalY,
          this.$i18n.t("MatchingBonus") +
            " - " +
            this.$i18n.t("BusinessCenter") +
            " 002"
        );

        let body = this.matching2.map(row =>
          Object.keys(row).map(key => row[key])
        );

        doc.autoTable({
          startY: finalY + 7,
          head: header,
          body: body
        });
      }

      if (this.matching3.length > 0) {
        let finalY = doc.previousAutoTable.finalY;
        finalY = finalY + 7;
        doc.text(
          13,
          finalY,
          this.$i18n.t("MatchingBonus") +
            " - " +
            this.$i18n.t("BusinessCenter") +
            " 003"
        );

        let body = this.matching3.map(row =>
          Object.keys(row).map(key => row[key])
        );

        doc.autoTable({
          startY: finalY + 7,
          head: header,
          body: body
        });
      }

      if (this.bonus1.length > 0) {
        let finalY = doc.previousAutoTable.finalY;
        finalY = finalY + 7;
        doc.text(
          13,
          finalY,
          this.$i18n.t("BaseBonus") +
            " - " +
            this.$i18n.t("BusinessCenter") +
            " 001"
        );

        let body = this.bonus1.map(row =>
          Object.keys(row).map(key => row[key])
        );

        doc.autoTable({
          startY: finalY + 7,
          head: header,
          body: body
        });
      }

      if (this.bonus2.length > 0) {
        let finalY = doc.previousAutoTable.finalY;
        finalY = finalY + 7;
        doc.text(
          13,
          finalY,
          this.$i18n.t("BaseBonus") +
            " - " +
            this.$i18n.t("BusinessCenter") +
            " 002"
        );

        let body = this.bonus2.map(row =>
          Object.keys(row).map(key => row[key])
        );

        doc.autoTable({
          startY: finalY + 7,
          head: header,
          body: body
        });
      }

      if (this.bonus3.length > 0) {
        let finalY = doc.previousAutoTable.finalY;
        finalY = finalY + 7;
        doc.text(
          13,
          finalY,
          this.$i18n.t("BaseBonus") +
            " - " +
            this.$i18n.t("BusinessCenter") +
            " 003"
        );

        let body = this.bonus3.map(row =>
          Object.keys(row).map(key => row[key])
        );

        doc.autoTable({
          startY: finalY + 7,
          head: header,
          body: body
        });
      }

      if (this.lifestyle1.length > 0) {
        let finalY = doc.previousAutoTable.finalY;
        finalY = finalY + 7;
        doc.text(
          13,
          finalY,
          this.$i18n.t("LifestyleBonus") +
            " - " +
            this.$i18n.t("BusinessCenter") +
            " 001"
        );

        let body = this.lifestyle1.map(row =>
          Object.keys(row).map(key => row[key])
        );

        doc.autoTable({
          startY: finalY + 7,
          head: header,
          body: body
        });
      }

      if (this.lifestyle2.length > 0) {
        let finalY = doc.previousAutoTable.finalY;
        finalY = finalY + 7;
        doc.text(
          13,
          finalY,
          this.$i18n.t("LifestyleBonus") +
            " - " +
            this.$i18n.t("BusinessCenter") +
            " 002"
        );

        let body = this.lifestyle2.map(row =>
          Object.keys(row).map(key => row[key])
        );

        doc.autoTable({
          startY: finalY + 7,
          head: header,
          body: body
        });
      }

      if (this.lifestyle3.length > 0) {
        let finalY = doc.previousAutoTable.finalY;
        finalY = finalY + 7;
        doc.text(
          13,
          finalY,
          this.$i18n.t("LifestyleBonus") +
            " - " +
            this.$i18n.t("BusinessCenter") +
            " 003"
        );

        let body = this.lifestyle3.map(row =>
          Object.keys(row).map(key => row[key])
        );

        doc.autoTable({
          startY: finalY + 7,
          head: header,
          body: body
        });
      }

      doc.addPage();
      doc.setFontType("bold");
      doc.setFontSize("14");
      doc.text(100, 20, this.$i18n.t("Summary"));
      doc.setFontSize("10");
      let types = this.periodItems;
      let y1 = 25;
      for (var j = 0; j < types.length; j++) {
        y1 = y1 + 5;
        doc.setFontType("bold");
        doc.text(110, y1, types[j].description + ": ");
        doc.setFontType("normal");
        doc.text(types[j].amount, 170, y1, "right");
      }

      doc.save(pdfName);
    },

    //Get earnings summary by ITA.
    async getPaidOnDetail() {
      await axios({
        method: "POST",
        url: process.env.VUE_APP_OVAPI + "getPaidOnDetail",
        data: {
          infotoken: localStorage.getItem("tokenInfo"),
          distid: this.itaCode,
          period: this.period
        },
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("loggedIn")
        }
      }).then(
        result => {
          var data = result.data.DATA;
          this.summary = data;
          this.setInformation(data);
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    setInformation(data) {
      let retail = 0;
      let fast = 0;
      let cycle = 0;
      let matching = 0;
      let bonus = 0;
      let lifestyle = 0;
      let retailJson = "[";
      let fastJson = "[";
      let cycleJson = "[";
      let matchingJson = "[";
      let bonusJson = "[";
      let lifestyleJson = "[";

      for (var i = 0; i < data.COM_DESC.length; i++) {
        let descrip = data.COM_DESC[i].toLowerCase().replace(/ /g, "");
        switch (descrip) {
          case "retailcommission":
            retail = retail + parseFloat(data.CONVERTED_BONUS[i]);
            retailJson = retailJson + this.setData(i, data);
            break;
          case "faststart":
            fast = fast + parseFloat(data.CONVERTED_BONUS[i]);
            fastJson = fastJson + this.setData(i, data);
            break;
          case "cyclecommission":
            cycle = cycle + parseFloat(data.CONVERTED_BONUS[i]);
            cycleJson = cycleJson + this.setData(i, data);
            break;
          case "matchingbonus":
            matching = matching + parseFloat(data.CONVERTED_BONUS[i]);
            matchingJson = matchingJson + this.setData(i, data);
            break;
          case "basebonus":
            bonus = bonus + parseFloat(data.CONVERTED_BONUS[i]);
            bonusJson = bonusJson + this.setData(i, data);
            break;
          case "lifestylebonus":
            lifestyle = lifestyle + parseFloat(data.CONVERTED_BONUS[i]);
            lifestyleJson = lifestyleJson + this.setData(i, data);
            break;
        }
      }

      //Set chart
      ApexCharts.exec("chart1", "updateSeries", [
        {
          data: [retail, fast, cycle, matching, bonus, lifestyle]
        }
      ]);

      this.total = (
        retail +
        fast +
        cycle +
        matching +
        bonus +
        lifestyle
      ).toFixed(2);

      //Set first table
      let json =
        '[{"description": "' +
        this.$i18n.t("RetailCommission") +
        '", "amount":"' +
        retail.toFixed(2) +
        '"},{"description": "' +
        this.$i18n.t("FastStart") +
        '", "amount":"' +
        fast.toFixed(2) +
        '"},{"description": "' +
        this.$i18n.t("CycleCommission") +
        '", "amount":"' +
        cycle.toFixed(2) +
        '"},{"description": "' +
        this.$i18n.t("MatchingBonus") +
        '", "amount":"' +
        matching.toFixed(2) +
        '"},{"description": "' +
        this.$i18n.t("BaseBonus") +
        '", "amount":"' +
        bonus.toFixed(2) +
        '"},{"description": "' +
        this.$i18n.t("LifestyleBonus") +
        '", "amount":"' +
        lifestyle.toFixed(2) +
        '"}]';
      this.periodItems = JSON.parse(json);

      this.convertJson(
        retailJson,
        fastJson,
        cycleJson,
        matchingJson,
        bonusJson,
        lifestyleJson
      );
    },

    setData(index, data) {
      let json = "";
      let soldName = data.SOLD_NAME[index];
      if (!soldName.includes("Carry")) {
        let amount = parseFloat(data.BONUS[index]).toFixed(2);
        if (this.$i18n.locale != "en") {
          soldName = soldName.includes("Cycle Number")
            ? (soldName = soldName.replace(
                /Cycle Number/g,
                this.$i18n.t("CycleNumber")
              ))
            : soldName;
        }
        json =
          '{"distributor":"' +
          data.DIST_ID[index] +
          '","soldName":"' +
          soldName +
          '","amountContribuited":"' +
          data.CURRENCY_CODE[index] +
          " " +
          amount +
          '","orderNumber":"' +
          data.TRANSACTION_NUMBER[index] +
          '","paidLevel":"' +
          data.PAID_LEVEL[index] +
          '","rank":"' +
          data.RANK[index] +
          '","name":"' +
          data.DST_DISPLAY_NAME_1[index] +
          '","percentContribuited":"' +
          data.PERCENT[index] +
          '","businessCenter":"' +
          data.DIST_BUS_CTR[index] +
          '"},';
      }
      return json;
    },

    convertJson(retail, fast, cycle, matching, bonus, lifestyle) {
      let json = "";
      retail =
        retail.length > 1 ? retail.substring(0, retail.length - 1) + "]" : "[]";
      json = JSON.parse(retail);

      this.retail1 = json.filter(json => json.businessCenter == "1");
      this.retail2 = json.filter(json => json.businessCenter == "2");
      this.retail3 = json.filter(json => json.businessCenter == "3");

      fast = fast.length > 1 ? fast.substring(0, fast.length - 1) + "]" : "[]";
      json = JSON.parse(fast);
      this.fast1 = json.filter(json => json.businessCenter == "1");
      this.fast2 = json.filter(json => json.businessCenter == "2");
      this.fast3 = json.filter(json => json.businessCenter == "3");

      cycle =
        cycle.length > 1 ? cycle.substring(0, cycle.length - 1) + "]" : "[]";
      json = JSON.parse(cycle);
      this.cycle1 = json.filter(json => json.businessCenter == "1");
      this.cycle2 = json.filter(json => json.businessCenter == "2");
      this.cycle3 = json.filter(json => json.businessCenter == "3");

      matching =
        matching.length > 1
          ? matching.substring(0, matching.length - 1) + "]"
          : "[]";
      json = JSON.parse(matching);
      this.matching1 = json.filter(json => json.businessCenter == "1");
      this.matching2 = json.filter(json => json.businessCenter == "2");
      this.matching3 = json.filter(json => json.businessCenter == "3");

      bonus =
        bonus.length > 1 ? bonus.substring(0, bonus.length - 1) + "]" : "[]";
      json = JSON.parse(bonus);
      this.bonus1 = json.filter(json => json.businessCenter == "1");
      this.bonus2 = json.filter(json => json.businessCenter == "2");
      this.bonus3 = json.filter(json => json.businessCenter == "3");

      lifestyle =
        lifestyle.length > 1
          ? lifestyle.substring(0, lifestyle.length - 1) + "]"
          : "[]";
      json = JSON.parse(lifestyle);
      this.lifestyle1 = json.filter(json => json.businessCenter == "1");
      this.lifestyle2 = json.filter(json => json.businessCenter == "2");
      this.lifestyle3 = json.filter(json => json.businessCenter == "3");
    }
  }
};
</script>
