var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "h4",
        { staticClass: "text-center text-dark font-semibold mb-base" },
        [
          _c("feather-icon", {
            attrs: {
              icon: "CalendarIcon",
              svgClasses: "h-8 w-8 mb-1 stroke-current text-success"
            }
          }),
          _vm._v(" \n    "),
          _c("strong", { staticClass: "font-semibold text-success" }, [
            _vm._v(
              _vm._s(
                _vm.$t(_vm.resources.PeriodDetails.i18n) ||
                  _vm.resources.PeriodDetail.name
              ) + ":"
            )
          ]),
          _vm._v("\n    " + _vm._s(_vm.periodLabel) + "\n  ")
        ],
        1
      ),
      _c(
        "vs-row",
        { staticClass: "mb-2" },
        [
          _c(
            "vs-col",
            {
              attrs: {
                "vs-type": "flex",
                "vs-justify": "center",
                "vs-align": "center",
                "vs-lg": "6",
                "vs-xs": "12"
              }
            },
            [
              _c(
                "vx-card",
                [
                  _c(
                    "vs-table",
                    {
                      attrs: { data: _vm.periodItems },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var data = ref.data
                            return [
                              _vm._l(data, function(tr, indextr) {
                                return _c(
                                  "vs-tr",
                                  { key: indextr },
                                  [
                                    _c(
                                      "vs-td",
                                      {
                                        attrs: {
                                          data: data[indextr].description
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(data[indextr].description)
                                        )
                                      ]
                                    ),
                                    _c(
                                      "vs-td",
                                      {
                                        staticClass: "text-right",
                                        attrs: { data: data[indextr].amount }
                                      },
                                      [_vm._v(_vm._s(data[indextr].amount))]
                                    )
                                  ],
                                  1
                                )
                              }),
                              _c(
                                "vs-tr",
                                [
                                  _c("vs-td", [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(_vm.resources.Total.i18n) ||
                                            _vm.resources.Total.name
                                        )
                                      )
                                    ])
                                  ]),
                                  _c("vs-td", { staticClass: "text-right" }, [
                                    _c("strong", [_vm._v(_vm._s(_vm.total))])
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c(
                        "template",
                        { slot: "thead" },
                        [
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.Description.i18n) ||
                                  _vm.resources.Description.name
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.EarningsAmount.i18n) ||
                                  _vm.resources.EarningsAmount.name
                              )
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    2
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "mt-2",
                      attrs: {
                        color: "rgb(187, 138, 200)",
                        type: "filled",
                        "icon-pack": "feather",
                        icon: "icon-download"
                      },
                      on: { click: _vm.downloadPDF }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.DownloadPDF.i18n) ||
                            _vm.resources.DownloadPDF.name
                        )
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "vs-col",
            {
              staticClass: "mt-4",
              attrs: {
                "vs-type": "flex",
                "vs-justify": "center",
                "vs-align": "center",
                "vs-lg": "6",
                "vs-xs": "12"
              }
            },
            [
              _c(
                "vs-col",
                {
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-lg": "12",
                    "vs-xs": "12"
                  }
                },
                [
                  _c("vx-card", { staticClass: "mb-base" }, [
                    _c(
                      "div",
                      {},
                      [
                        _c("vue-apex-charts", {
                          attrs: {
                            type: "bar",
                            height: "350",
                            options: _vm.columnChart.chartOptions,
                            series: _vm.columnChart.series
                          }
                        })
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "vs-row",
        [
          _c(
            "vs-col",
            {
              attrs: {
                "vs-type": "flex",
                "vs-justify": "center",
                "vs-align": "center",
                "vs-lg": "12",
                "vs-xs": "12"
              }
            },
            [
              _c(
                "vx-card",
                {
                  attrs: {
                    title:
                      _vm.$t(_vm.resources.Detail.i18n) ||
                      _vm.resources.Detail.name
                  }
                },
                [
                  _c(
                    "vs-collapse",
                    [
                      _c(
                        "vs-collapse-item",
                        [
                          _c(
                            "div",
                            { attrs: { slot: "header" }, slot: "header" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(_vm.resources.RetailCommission.i18n) ||
                                    _vm.resources.RetailCommission.name
                                )
                              )
                            ]
                          ),
                          _c(
                            "vs-tabs",
                            [
                              _c(
                                "vs-tab",
                                {
                                  attrs: {
                                    label:
                                      (_vm.$t(
                                        _vm.resources.BusinessCenter.i18n
                                      ) || _vm.resources.BusinessCenter.name) +
                                      " 001"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "tab-text" },
                                    [
                                      _c(
                                        "vs-table",
                                        {
                                          attrs: { data: _vm.retail1 },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var data = ref.data
                                                return _vm._l(data, function(
                                                  tr,
                                                  indextr
                                                ) {
                                                  return _c(
                                                    "vs-tr",
                                                    { key: indextr },
                                                    [
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .distributor
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .distributor
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr].name
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr].name
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .paidLevel
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .paidLevel
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr].rank
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr].rank
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .percentContribuited
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .percentContribuited
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .amountContribuited
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .amountContribuited
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                })
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _c(
                                            "template",
                                            { slot: "thead" },
                                            [
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .DistributorID.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .DistributorID.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.Name.i18n
                                                    ) || _vm.resources.Name.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.PaidRank
                                                        .i18n
                                                    ) ||
                                                      _vm.resources.PaidRank
                                                        .name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.PinRank.i18n
                                                    ) ||
                                                      _vm.resources.PinRank.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .PercentContributed.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .PercentContributed.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .AmountContributed.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .AmountContributed.name
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "vs-tab",
                                {
                                  attrs: {
                                    label:
                                      (_vm.$t(
                                        _vm.resources.BusinessCenter.i18n
                                      ) || _vm.resources.BusinessCenter.name) +
                                      " 002"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "tab-text" },
                                    [
                                      _c(
                                        "vs-table",
                                        {
                                          attrs: { data: _vm.retail2 },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var data = ref.data
                                                return _vm._l(data, function(
                                                  tr,
                                                  indextr
                                                ) {
                                                  return _c(
                                                    "vs-tr",
                                                    { key: indextr },
                                                    [
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .distributor
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .distributor
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr].name
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr].name
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .paidLevel
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .paidLevel
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr].rank
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr].rank
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .percentContribuited
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .percentContribuited
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .amountContribuited
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .amountContribuited
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                })
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _c(
                                            "template",
                                            { slot: "thead" },
                                            [
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .DistributorID.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .DistributorID.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.Name.i18n
                                                    ) || _vm.resources.Name.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.PaidRank
                                                        .i18n
                                                    ) ||
                                                      _vm.resources.PaidRank
                                                        .name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.PinRank.i18n
                                                    ) ||
                                                      _vm.resources.PinRank.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .PercentContributed.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .PercentContributed.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .AmountContributed.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .AmountContributed.name
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "vs-tab",
                                {
                                  attrs: {
                                    label:
                                      (_vm.$t(
                                        _vm.resources.BusinessCenter.i18n
                                      ) || _vm.resources.BusinessCenter.name) +
                                      " 003"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "tab-text" },
                                    [
                                      _c(
                                        "vs-table",
                                        {
                                          attrs: { data: _vm.retail3 },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var data = ref.data
                                                return _vm._l(data, function(
                                                  tr,
                                                  indextr
                                                ) {
                                                  return _c(
                                                    "vs-tr",
                                                    { key: indextr },
                                                    [
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .distributor
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .distributor
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr].name
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr].name
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .paidLevel
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .paidLevel
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr].rank
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr].rank
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .percentContribuited
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .percentContribuited
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .amountContribuited
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .amountContribuited
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                })
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _c(
                                            "template",
                                            { slot: "thead" },
                                            [
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .DistributorID.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .DistributorID.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.Name.i18n
                                                    ) || _vm.resources.Name.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.PaidRank
                                                        .i18n
                                                    ) ||
                                                      _vm.resources.PaidRank
                                                        .name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.PinRank.i18n
                                                    ) ||
                                                      _vm.resources.PinRank.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .PercentContributed.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .PercentContributed.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .AmountContributed.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .AmountContributed.name
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "vs-collapse-item",
                        [
                          _c(
                            "div",
                            { attrs: { slot: "header" }, slot: "header" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(_vm.resources.FastStart.i18n) ||
                                    _vm.resources.FastStart.name
                                )
                              )
                            ]
                          ),
                          _c(
                            "vs-tabs",
                            [
                              _c(
                                "vs-tab",
                                {
                                  attrs: {
                                    label:
                                      (_vm.$t(
                                        _vm.resources.BusinessCenter.i18n
                                      ) || _vm.resources.BusinessCenter.name) +
                                      " 001"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "tab-text" },
                                    [
                                      _c(
                                        "vs-table",
                                        {
                                          attrs: { data: _vm.fast1 },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var data = ref.data
                                                return _vm._l(data, function(
                                                  tr,
                                                  indextr
                                                ) {
                                                  return _c(
                                                    "vs-tr",
                                                    { key: indextr },
                                                    [
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .distributor
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .distributor
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr].name
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr].name
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .paidLevel
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .paidLevel
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr].rank
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr].rank
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .percentContribuited
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .percentContribuited
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .amountContribuited
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .amountContribuited
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                })
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _c(
                                            "template",
                                            { slot: "thead" },
                                            [
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .DistributorID.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .DistributorID.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.Name.i18n
                                                    ) || _vm.resources.Name.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.PaidRank
                                                        .i18n
                                                    ) ||
                                                      _vm.resources.PaidRank
                                                        .name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.PinRank.i18n
                                                    ) ||
                                                      _vm.resources.PinRank.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .PercentContributed.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .PercentContributed.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .AmountContributed.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .AmountContributed.name
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "vs-tab",
                                {
                                  attrs: {
                                    label:
                                      (_vm.$t(
                                        _vm.resources.BusinessCenter.i18n
                                      ) || _vm.resources.BusinessCenter.name) +
                                      " 002"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "tab-text" },
                                    [
                                      _c(
                                        "vs-table",
                                        {
                                          attrs: { data: _vm.fast2 },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var data = ref.data
                                                return _vm._l(data, function(
                                                  tr,
                                                  indextr
                                                ) {
                                                  return _c(
                                                    "vs-tr",
                                                    { key: indextr },
                                                    [
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .distributor
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .distributor
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr].name
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr].name
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .paidLevel
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .paidLevel
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr].rank
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr].rank
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .percentContribuited
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .percentContribuited
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .amountContribuited
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .amountContribuited
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                })
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _c(
                                            "template",
                                            { slot: "thead" },
                                            [
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .DistributorID.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .DistributorID.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.Name.i18n
                                                    ) || _vm.resources.Name.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.PaidRank
                                                        .i18n
                                                    ) ||
                                                      _vm.resources.PaidRank
                                                        .name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.PinRank.i18n
                                                    ) ||
                                                      _vm.resources.PinRank.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .PercentContributed.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .PercentContributed.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .AmountContributed.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .AmountContributed.name
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "vs-tab",
                                {
                                  attrs: {
                                    label:
                                      (_vm.$t(
                                        _vm.resources.BusinessCenter.i18n
                                      ) || _vm.resources.BusinessCenter.name) +
                                      " 003"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "tab-text" },
                                    [
                                      _c(
                                        "vs-table",
                                        {
                                          attrs: { data: _vm.fast3 },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var data = ref.data
                                                return _vm._l(data, function(
                                                  tr,
                                                  indextr
                                                ) {
                                                  return _c(
                                                    "vs-tr",
                                                    { key: indextr },
                                                    [
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .distributor
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .distributor
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr].name
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr].name
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .paidLevel
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .paidLevel
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr].rank
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr].rank
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .percentContribuited
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .percentContribuited
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .amountContribuited
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .amountContribuited
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                })
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _c(
                                            "template",
                                            { slot: "thead" },
                                            [
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .DistributorID.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .DistributorID.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.Name.i18n
                                                    ) || _vm.resources.Name.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.PaidRank
                                                        .i18n
                                                    ) ||
                                                      _vm.resources.PaidRank
                                                        .name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.PinRank.i18n
                                                    ) ||
                                                      _vm.resources.PinRank.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .PercentContributed.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .PercentContributed.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .AmountContributed.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .AmountContributed.name
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "vs-collapse-item",
                        [
                          _c(
                            "div",
                            { attrs: { slot: "header" }, slot: "header" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(_vm.resources.CycleCommission.i18n) ||
                                    _vm.resources.CycleCommission.name
                                )
                              )
                            ]
                          ),
                          _c(
                            "vs-tabs",
                            [
                              _c(
                                "vs-tab",
                                {
                                  attrs: {
                                    label:
                                      (_vm.$t(
                                        _vm.resources.BusinessCenter.i18n
                                      ) || _vm.resources.BusinessCenter.name) +
                                      " 001"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "tab-text" },
                                    [
                                      _c(
                                        "vs-table",
                                        {
                                          attrs: { data: _vm.cycle1 },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var data = ref.data
                                                return _vm._l(data, function(
                                                  tr,
                                                  indextr
                                                ) {
                                                  return _c(
                                                    "vs-tr",
                                                    { key: indextr },
                                                    [
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .distributor
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .distributor
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr].name
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr].name
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .paidLevel
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .paidLevel
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr].rank
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr].rank
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .percentContribuited
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .percentContribuited
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .amountContribuited
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .amountContribuited
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                })
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _c(
                                            "template",
                                            { slot: "thead" },
                                            [
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .DistributorID.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .DistributorID.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.Name.i18n
                                                    ) || _vm.resources.Name.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.PaidRank
                                                        .i18n
                                                    ) ||
                                                      _vm.resources.PaidRank
                                                        .name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.PinRank.i18n
                                                    ) ||
                                                      _vm.resources.PinRank.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .PercentContributed.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .PercentContributed.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .AmountContributed.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .AmountContributed.name
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "vs-tab",
                                {
                                  attrs: {
                                    label:
                                      (_vm.$t(
                                        _vm.resources.BusinessCenter.i18n
                                      ) || _vm.resources.BusinessCenter.name) +
                                      " 002"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "tab-text" },
                                    [
                                      _c(
                                        "vs-table",
                                        {
                                          attrs: { data: _vm.cycle2 },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var data = ref.data
                                                return _vm._l(data, function(
                                                  tr,
                                                  indextr
                                                ) {
                                                  return _c(
                                                    "vs-tr",
                                                    { key: indextr },
                                                    [
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .distributor
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .distributor
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr].name
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr].name
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .paidLevel
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .paidLevel
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr].rank
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr].rank
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .percentContribuited
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .percentContribuited
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .amountContribuited
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .amountContribuited
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                })
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _c(
                                            "template",
                                            { slot: "thead" },
                                            [
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .DistributorID.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .DistributorID.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.Name.i18n
                                                    ) || _vm.resources.Name.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.PaidRank
                                                        .i18n
                                                    ) ||
                                                      _vm.resources.PaidRank
                                                        .name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.PinRank.i18n
                                                    ) ||
                                                      _vm.resources.PinRank.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .PercentContributed.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .PercentContributed.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .AmountContributed.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .AmountContributed.name
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "vs-tab",
                                {
                                  attrs: {
                                    label:
                                      (_vm.$t(
                                        _vm.resources.BusinessCenter.i18n
                                      ) || _vm.resources.BusinessCenter.name) +
                                      " 003"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "tab-text" },
                                    [
                                      _c(
                                        "vs-table",
                                        {
                                          attrs: { data: _vm.cycle3 },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var data = ref.data
                                                return _vm._l(data, function(
                                                  tr,
                                                  indextr
                                                ) {
                                                  return _c(
                                                    "vs-tr",
                                                    { key: indextr },
                                                    [
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .distributor
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .distributor
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr].name
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr].name
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .paidLevel
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .paidLevel
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr].rank
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr].rank
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .percentContribuited
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .percentContribuited
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .amountContribuited
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .amountContribuited
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                })
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _c(
                                            "template",
                                            { slot: "thead" },
                                            [
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .DistributorID.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .DistributorID.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.Name.i18n
                                                    ) || _vm.resources.Name.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.PaidRank
                                                        .i18n
                                                    ) ||
                                                      _vm.resources.PaidRank
                                                        .name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.PinRank.i18n
                                                    ) ||
                                                      _vm.resources.PinRank.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .PercentContributed.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .PercentContributed.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .AmountContributed.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .AmountContributed.name
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "vs-collapse-item",
                        [
                          _c(
                            "div",
                            { attrs: { slot: "header" }, slot: "header" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(_vm.resources.MatchingBonus.i18n) ||
                                    _vm.resources.MatchingBonus.name
                                )
                              )
                            ]
                          ),
                          _c(
                            "vs-tabs",
                            [
                              _c(
                                "vs-tab",
                                {
                                  attrs: {
                                    label:
                                      (_vm.$t(
                                        _vm.resources.BusinessCenter.i18n
                                      ) || _vm.resources.BusinessCenter.name) +
                                      " 001"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "tab-text" },
                                    [
                                      _c(
                                        "vs-table",
                                        {
                                          attrs: { data: _vm.matching1 },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var data = ref.data
                                                return _vm._l(data, function(
                                                  tr,
                                                  indextr
                                                ) {
                                                  return _c(
                                                    "vs-tr",
                                                    { key: indextr },
                                                    [
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .distributor
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .distributor
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr].name
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr].name
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .paidLevel
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .paidLevel
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr].rank
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr].rank
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .percentContribuited
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .percentContribuited
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .amountContribuited
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .amountContribuited
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                })
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _c(
                                            "template",
                                            { slot: "thead" },
                                            [
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .DistributorID.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .DistributorID.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.Name.i18n
                                                    ) || _vm.resources.Name.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.PaidRank
                                                        .i18n
                                                    ) ||
                                                      _vm.resources.PaidRank
                                                        .name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.PinRank.i18n
                                                    ) ||
                                                      _vm.resources.PinRank.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .PercentContributed.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .PercentContributed.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .AmountContributed.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .AmountContributed.name
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "vs-tab",
                                {
                                  attrs: {
                                    label:
                                      (_vm.$t(
                                        _vm.resources.BusinessCenter.i18n
                                      ) || _vm.resources.BusinessCenter.name) +
                                      " 002"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "tab-text" },
                                    [
                                      _c(
                                        "vs-table",
                                        {
                                          attrs: { data: _vm.matching2 },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var data = ref.data
                                                return _vm._l(data, function(
                                                  tr,
                                                  indextr
                                                ) {
                                                  return _c(
                                                    "vs-tr",
                                                    { key: indextr },
                                                    [
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .distributor
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .distributor
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr].name
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr].name
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .paidLevel
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .paidLevel
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr].rank
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr].rank
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .percentContribuited
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .percentContribuited
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .amountContribuited
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .amountContribuited
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                })
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _c(
                                            "template",
                                            { slot: "thead" },
                                            [
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .DistributorID.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .DistributorID.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.Name.i18n
                                                    ) || _vm.resources.Name.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.PaidRank
                                                        .i18n
                                                    ) ||
                                                      _vm.resources.PaidRank
                                                        .name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.PinRank.i18n
                                                    ) ||
                                                      _vm.resources.PinRank.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .PercentContributed.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .PercentContributed.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .AmountContributed.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .AmountContributed.name
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "vs-tab",
                                {
                                  attrs: {
                                    label:
                                      (_vm.$t(
                                        _vm.resources.BusinessCenter.i18n
                                      ) || _vm.resources.BusinessCenter.name) +
                                      " 003"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "tab-text" },
                                    [
                                      _c(
                                        "vs-table",
                                        {
                                          attrs: { data: _vm.matching3 },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var data = ref.data
                                                return _vm._l(data, function(
                                                  tr,
                                                  indextr
                                                ) {
                                                  return _c(
                                                    "vs-tr",
                                                    { key: indextr },
                                                    [
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .distributor
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .distributor
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr].name
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr].name
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .paidLevel
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .paidLevel
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr].rank
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr].rank
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .percentContribuited
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .percentContribuited
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .amountContribuited
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .amountContribuited
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                })
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _c(
                                            "template",
                                            { slot: "thead" },
                                            [
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .DistributorID.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .DistributorID.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.Name.i18n
                                                    ) || _vm.resources.Name.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.PaidRank
                                                        .i18n
                                                    ) ||
                                                      _vm.resources.PaidRank
                                                        .name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.PinRank.i18n
                                                    ) ||
                                                      _vm.resources.PinRank.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .PercentContributed.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .PercentContributed.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .AmountContributed.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .AmountContributed.name
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "vs-collapse-item",
                        [
                          _c(
                            "div",
                            { attrs: { slot: "header" }, slot: "header" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(_vm.resources.BaseBonus.i18n) ||
                                    _vm.resources.BaseBonus.name
                                )
                              )
                            ]
                          ),
                          _c(
                            "vs-tabs",
                            [
                              _c(
                                "vs-tab",
                                {
                                  attrs: {
                                    label:
                                      (_vm.$t(
                                        _vm.resources.BusinessCenter.i18n
                                      ) || _vm.resources.BusinessCenter.name) +
                                      " 001"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "tab-text" },
                                    [
                                      _c(
                                        "vs-table",
                                        {
                                          attrs: { data: _vm.bonus1 },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var data = ref.data
                                                return _vm._l(data, function(
                                                  tr,
                                                  indextr
                                                ) {
                                                  return _c(
                                                    "vs-tr",
                                                    { key: indextr },
                                                    [
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .distributor
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .distributor
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr].name
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr].name
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .paidLevel
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .paidLevel
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr].rank
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr].rank
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .percentContribuited
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .percentContribuited
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .amountContribuited
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .amountContribuited
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                })
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _c(
                                            "template",
                                            { slot: "thead" },
                                            [
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .DistributorID.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .DistributorID.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.Name.i18n
                                                    ) || _vm.resources.Name.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.PaidRank
                                                        .i18n
                                                    ) ||
                                                      _vm.resources.PaidRank
                                                        .name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.PinRank.i18n
                                                    ) ||
                                                      _vm.resources.PinRank.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .PercentContributed.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .PercentContributed.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .AmountContributed.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .AmountContributed.name
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "vs-tab",
                                {
                                  attrs: {
                                    label:
                                      (_vm.$t(
                                        _vm.resources.BusinessCenter.i18n
                                      ) || _vm.resources.BusinessCenter.name) +
                                      " 002"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "tab-text" },
                                    [
                                      _c(
                                        "vs-table",
                                        {
                                          attrs: { data: _vm.bonus2 },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var data = ref.data
                                                return _vm._l(data, function(
                                                  tr,
                                                  indextr
                                                ) {
                                                  return _c(
                                                    "vs-tr",
                                                    { key: indextr },
                                                    [
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .distributor
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .distributor
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr].name
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr].name
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .paidLevel
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .paidLevel
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr].rank
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr].rank
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .percentContribuited
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .percentContribuited
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .amountContribuited
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .amountContribuited
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                })
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _c(
                                            "template",
                                            { slot: "thead" },
                                            [
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .DistributorID.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .DistributorID.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.Name.i18n
                                                    ) || _vm.resources.Name.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.PaidRank
                                                        .i18n
                                                    ) ||
                                                      _vm.resources.PaidRank
                                                        .name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.PinRank.i18n
                                                    ) ||
                                                      _vm.resources.PinRank.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .PercentContributed.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .PercentContributed.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .AmountContributed.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .AmountContributed.name
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "vs-tab",
                                {
                                  attrs: {
                                    label:
                                      (_vm.$t(
                                        _vm.resources.BusinessCenter.i18n
                                      ) || _vm.resources.BusinessCenter.name) +
                                      " 003"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "tab-text" },
                                    [
                                      _c(
                                        "vs-table",
                                        {
                                          attrs: { data: _vm.bonus3 },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var data = ref.data
                                                return _vm._l(data, function(
                                                  tr,
                                                  indextr
                                                ) {
                                                  return _c(
                                                    "vs-tr",
                                                    { key: indextr },
                                                    [
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .distributor
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .distributor
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr].name
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr].name
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .paidLevel
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .paidLevel
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr].rank
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr].rank
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .percentContribuited
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .percentContribuited
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .amountContribuited
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .amountContribuited
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                })
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _c(
                                            "template",
                                            { slot: "thead" },
                                            [
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .DistributorID.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .DistributorID.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.Name.i18n
                                                    ) || _vm.resources.Name.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.PaidRank
                                                        .i18n
                                                    ) ||
                                                      _vm.resources.PaidRank
                                                        .name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.PinRank.i18n
                                                    ) ||
                                                      _vm.resources.PinRank.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .PercentContributed.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .PercentContributed.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .AmountContributed.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .AmountContributed.name
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "vs-collapse-item",
                        [
                          _c(
                            "div",
                            { attrs: { slot: "header" }, slot: "header" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(_vm.resources.LifestyleBonus.i18n) ||
                                    _vm.resources.LifestyleBonus.name
                                )
                              )
                            ]
                          ),
                          _c(
                            "vs-tabs",
                            [
                              _c(
                                "vs-tab",
                                {
                                  attrs: {
                                    label:
                                      (_vm.$t(
                                        _vm.resources.BusinessCenter.i18n
                                      ) || _vm.resources.BusinessCenter.name) +
                                      " 001"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "tab-text" },
                                    [
                                      _c(
                                        "vs-table",
                                        {
                                          attrs: { data: _vm.lifestyle1 },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var data = ref.data
                                                return _vm._l(data, function(
                                                  tr,
                                                  indextr
                                                ) {
                                                  return _c(
                                                    "vs-tr",
                                                    { key: indextr },
                                                    [
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .distributor
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .distributor
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr].name
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr].name
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .paidLevel
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .paidLevel
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr].rank
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr].rank
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .percentContribuited
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .percentContribuited
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .amountContribuited
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .amountContribuited
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                })
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _c(
                                            "template",
                                            { slot: "thead" },
                                            [
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .DistributorID.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .DistributorID.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.Name.i18n
                                                    ) || _vm.resources.Name.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.PaidRank
                                                        .i18n
                                                    ) ||
                                                      _vm.resources.PaidRank
                                                        .name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.PinRank.i18n
                                                    ) ||
                                                      _vm.resources.PinRank.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .PercentContributed.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .PercentContributed.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .AmountContributed.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .AmountContributed.name
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "vs-tab",
                                {
                                  attrs: {
                                    label:
                                      (_vm.$t(
                                        _vm.resources.BusinessCenter.i18n
                                      ) || _vm.resources.BusinessCenter.name) +
                                      " 002"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "tab-text" },
                                    [
                                      _c(
                                        "vs-table",
                                        {
                                          attrs: { data: _vm.lifestyle2 },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var data = ref.data
                                                return _vm._l(data, function(
                                                  tr,
                                                  indextr
                                                ) {
                                                  return _c(
                                                    "vs-tr",
                                                    { key: indextr },
                                                    [
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .distributor
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .distributor
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr].name
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr].name
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .paidLevel
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .paidLevel
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr].rank
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr].rank
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .percentContribuited
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .percentContribuited
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .amountContribuited
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .amountContribuited
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                })
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _c(
                                            "template",
                                            { slot: "thead" },
                                            [
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .DistributorID.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .DistributorID.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.Name.i18n
                                                    ) || _vm.resources.Name.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.PaidRank
                                                        .i18n
                                                    ) ||
                                                      _vm.resources.PaidRank
                                                        .name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.PinRank.i18n
                                                    ) ||
                                                      _vm.resources.PinRank.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .PercentContributed.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .PercentContributed.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .AmountContributed.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .AmountContributed.name
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "vs-tab",
                                {
                                  attrs: {
                                    label:
                                      (_vm.$t(
                                        _vm.resources.BusinessCenter.i18n
                                      ) || _vm.resources.BusinessCenter.name) +
                                      " 003"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "tab-text" },
                                    [
                                      _c(
                                        "vs-table",
                                        {
                                          attrs: { data: _vm.lifestyle3 },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var data = ref.data
                                                return _vm._l(data, function(
                                                  tr,
                                                  indextr
                                                ) {
                                                  return _c(
                                                    "vs-tr",
                                                    { key: indextr },
                                                    [
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .distributor
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .distributor
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr].name
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr].name
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .paidLevel
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .paidLevel
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr].rank
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr].rank
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .percentContribuited
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .percentContribuited
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          attrs: {
                                                            data:
                                                              data[indextr]
                                                                .amountContribuited
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .amountContribuited
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                })
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _c(
                                            "template",
                                            { slot: "thead" },
                                            [
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .DistributorID.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .DistributorID.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.Name.i18n
                                                    ) || _vm.resources.Name.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.PaidRank
                                                        .i18n
                                                    ) ||
                                                      _vm.resources.PaidRank
                                                        .name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.PinRank.i18n
                                                    ) ||
                                                      _vm.resources.PinRank.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .PercentContributed.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .PercentContributed.name
                                                  )
                                                )
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .AmountContributed.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .AmountContributed.name
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "vs-row",
        { attrs: { "vs-type": "flex", "vs-justify": "flex-end" } },
        [
          _c(
            "vs-button",
            {
              staticClass: "mt-2",
              attrs: { color: "rgb(174,174,174)", type: "filled" },
              on: {
                click: function($event) {
                  return _vm.$router.push("/commissions/Index")
                }
              }
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$t(_vm.resources.Return.i18n) || _vm.resources.Return.name
                )
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }